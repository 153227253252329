import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { route } from 'ziggy-js'
import axios from '@/axios'
import type DefaultWeekBackend from '@/Backend/models/DefaultWeekBackend'
import type TeamBackend from '@/Backend/models/TeamBackend'
import type UserBackend from '@/Backend/models/UserBackend'
import type CompanyBackend from '@/Backend/models/CompanyBackend'
import { availableLanguages, setLocale } from '@/i18n'
import dayjs from '@/dayjs'
import { useGetDefaultWeek } from '@/Query/defaultWeek'
import type UserHierarchy from '@/Frontend/UserHierarchy'

export const useUserStore = defineStore('user', () => {
  const user = ref<UserBackend | null>(null)
  const teams = ref<TeamBackend[] | null>(null)
  const company = ref<CompanyBackend | null>(null)
  const hierarchy = ref<UserHierarchy | null>(null)
  const isLoading = ref(true)
  const defaultWeek = ref<DefaultWeekBackend | null>(null)

  const fetchUser = async () => {
    if (!user.value) {
      const { data } = await axios.get<{ data: UserBackend }>(route('api.v1.users.me'))
      user.value = data.data
      company.value = data.data.company ?? null
      teams.value = data.data.teams ?? null
      defaultWeek.value = data.data.default_week ?? null
      hierarchy.value = {
        nPlus1: data.data.hierarchy?.nPlus1 ?? null,
        n: data.data.hierarchy?.n ?? [],
        nMinus1: data.data.hierarchy?.nMinus1 ?? []
      }
    }
    await initializeLocale()
    isLoading.value = false
  }

  const fetchDefaultWeek = async (force = false) => {
    if (!defaultWeek.value || force) {
      const { data } = await useGetDefaultWeek()
      defaultWeek.value = data.value?.data ?? null
    }
  }

  const lang = computed(() => {
    const navigatorLanguage = navigator.language.substring(0, 2)
    return (
      user.value?.lang ??
      (availableLanguages.includes(navigatorLanguage) ? navigatorLanguage : 'fr')
    )
  })

  const timezone = computed(() => {
    return user.value?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
  })

  const isTeamManager = computed(() => {
    return teams.value?.some((t) => t.is_manager)
  })

  const isManager = computed(() => {
    const nMinus1 = hierarchy.value?.nMinus1
    return (nMinus1 && nMinus1.length > 0) || teams.value?.some((t) => t.is_manager)
  })

  const isManagerOf = (userId: number) => {
    return hierarchy.value?.nMinus1 ? hierarchy.value?.nMinus1.some((u) => u.id === userId) : false
  }

  const personalTeam = computed(() => {
    let data: UserBackend[] = []
    if (hierarchy.value) {
      data = data.concat(hierarchy.value.nMinus1.concat(hierarchy.value.n))
      if (hierarchy.value.nPlus1) {
        data.push(hierarchy.value.nPlus1 as UserBackend)
      }
      return data
    }
    return data
  })

  const initializeLocale = async () => {
    await setLocale(lang.value)
    dayjs.locale(lang.value)
    dayjs.tz.setDefault(timezone.value)
  }

  const setUser = (data: UserBackend) => {
    user.value = data
    return data
  }

  return {
    initializeLocale,
    fetchUser,
    setUser,
    user,
    lang,
    timezone,
    isLoading,
    isManager,
    isManagerOf,
    teams,
    hierarchy,
    defaultWeek,
    isTeamManager,
    personalTeam,
    company,
    fetchDefaultWeek
  }
})
