import { defineStore } from 'pinia'
import { ref } from 'vue'
import { i18n } from '@/i18n'
import { route } from 'ziggy-js'
import axios from '@/axios'
import type UserBackend from '@/Backend/models/UserBackend'
import type OfficeBackend from '@/Backend/models/OfficeBackend'
import type MeetingRoomBackend from '@/Backend/models/MeetingRoomBackend'
import type ZoneBackend from '@/Backend/models/ZoneBackend'
import notify from '@/notify'

export const useLikedStore = defineStore('liked', () => {
  const { t } = i18n
  type likedType = {
    'App\\Models\\User': number[]
    'App\\Models\\Office': number[]
    'App\\Models\\MeetingRoom': number[]
    'App\\Models\\Zone': number[]
  }

  const liked = ref<likedType | null>(null)
  const isLoading = ref(true)
  const defaultState: likedType = {
    'App\\Models\\User': [] as number[],
    'App\\Models\\Office': [] as number[],
    'App\\Models\\MeetingRoom': [] as number[],
    'App\\Models\\Zone': [] as number[]
  }

  type likeableType =
    | 'App\\Models\\User'
    | 'App\\Models\\Office'
    | 'App\\Models\\MeetingRoom'
    | 'App\\Models\\Zone'

  async function fetchLiked() {
    if (!liked.value) {
      return axios.get(route(`api.v1.liked`)).then(({ data }) => {
        liked.value = {
          ...defaultState,
          ...data
        }
        isLoading.value = false
      })
    }
  }

  function like(id: number, likeable_type: likeableType) {
    axios
      .post(route('api.v1.like'), {
        id,
        likeable_type
      })
      .then(
        ({
          data
        }: {
          data: { likeable: UserBackend | OfficeBackend | MeetingRoomBackend | ZoneBackend }
        }) => {
          if (liked.value) {
            liked.value[likeable_type].push(id)
          }
          notify({
            type: 'success',
            message: t('days.calendar.card.success_like', { user: data.likeable.name }),
            duration: 2500
          })
        }
      )
  }

  function unlike(id: number, likeable_type: likeableType) {
    axios
      .delete(route('api.v1.unlike'), {
        params: {
          id,
          likeable_type
        }
      })
      .then(
        ({
          data
        }: {
          data: { likeable: UserBackend | OfficeBackend | MeetingRoomBackend | ZoneBackend }
        }) => {
          if (liked.value) {
            liked.value[likeable_type].splice(
              liked.value[likeable_type].indexOf(data.likeable.id),
              1
            )
          }
          notify({
            type: 'success',
            message: t('days.calendar.card.success_unlike', { user: data.likeable.name }),
            duration: 2500
          })
        }
      )
  }

  return { liked, fetchLiked, like, unlike, isLoading }
})
