import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import 'dayjs/locale/es'
import 'dayjs/locale/it'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isoWeek from 'dayjs/plugin/isoWeek'
import weekday from 'dayjs/plugin/weekday'
import { useUserStore } from '@/Store/UserStore'

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(weekday)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isoWeek)

export function dayjsHuman(date, short = false) {
  let string
  let day = dayjs(date)
  const userStore = useUserStore()
  const { lang } = userStore
  switch (lang) {
    case 'fr':
    default:
      if (short) {
        string = day.format('ddd Do MMM')
      } else {
        string = day.format('dddd Do MMMM')
      }
      string = string.charAt(0).toUpperCase() + string.slice(1)
      break
    case 'de':
      if (short) {
        string = day.format('ddd, D. MMM')
      } else {
        string = day.format('dddd, D. MMMM')
      }
      break
    case 'it':
      if (short) {
        string = day.format('ddd D MMM')
      } else {
        string = day.format('dddd D MMMM')
      }
      string = string.charAt(0).toUpperCase() + string.slice(1)
      break
    case 'es':
      if (short) {
        string = day.format('ddd D MMM')
      } else {
        string = day.format('dddd D MMMM')
      }
      string = string.charAt(0).toUpperCase() + string.slice(1)
      break
    case 'en':
      if (short) {
        string = day.format('ddd, MMM Do')
      } else {
        string = day.format('dddd, MMMM Do')
      }
      break
  }
  return string
}

export default dayjs
